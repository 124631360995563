import { graphql } from 'gatsby';
import * as React from "react"
import Layout from '~/components/Layout';
import FlexibleContent from '~/components/FlexibleContent';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import CustomSEO from '~/components/seo/CustomSEO';

export default function defaultPage({ data: { wpPage } }) {

  const content = wpPage?.page?.page?.content;
  const hero = content?.find(({ fieldGroupName }) => fieldGroupName === 'page_Page_Page_Content_Hero')?.hero;
  const heroTwoColumn = content?.find(({ fieldGroupName }) => fieldGroupName === 'page_Page_Page_Content_HeroTwoColumn')?.heroTwoColumn;

  const customSeo = {
    ...wpPage?.seo,
    image: hero?.image ? hero?.image : heroTwoColumn?.image,
  }

  return (
    <Layout
      headerWhite={wpPage.page.page.whiteHeader}
      backgroundColour={wpPage.page.page.backgroundColour}
      showLogin={wpPage.page.page.showLogin}
      footerCtaType={wpPage.page.page.footerCtaType}
      footerResourcesType={wpPage.page.page.footerResourcesType}
      pageLanguage={wpPage.page.page.pageLanguage}
    >
      <Seo post={wpPage} />
      <CustomSEO seo={customSeo} />
      <FlexibleContent content={wpPage.page.page.content} />
    </Layout>
  )
}

export const defaultPageQuery = graphql`
  query defaultPagePageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      page {
        page {
          backgroundColour
          whiteHeader
          showLogin
          footerCtaType
          footerResourcesType
          pageLanguage
          content {
            ... on WpPage_Page_Page_Content_TwoColumns {
              fieldGroupName
              twoColumns {
                fieldGroupName
                alignImage
                backgroundColour {
                  colour
                  colourSize
                  alignColour
                  padding {
                    top
                    bottom
                  }
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText
                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                image {
                  ... Image
                }
                secondImage {
                  ... Image
                }
                thirdImage {
                  ... Image
                }
                alignSecondImage
              }
            }
            ... on WpPage_Page_Page_Content_Testimonial {
              fieldGroupName
              testimonial {
                fieldGroupName
                backgroundColour {
                  colour
                  colourSize
                  alignColour
                  padding {
                    top
                    bottom
                  }
                }
                quote
                fieldGroupName
                author {
                  role
                  name
                  fieldGroupName
                }
                hidetrustpilotwidget
                quoteTagType
                link {
                  ... Button
                }
                innerBackgroundColour
                alignImage
                image {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_Features {
              fieldGroupName
              features {
                fieldGroupName
                layout
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                features {
                  content
                  fieldGroupName
                  heading
                  image {
                    ... Image
                  }
                  link {
                    ... Button
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_FullWidthImage {
              fieldGroupName
              image {
                ... Image
              }
            }
            ... on WpPage_Page_Page_Content_Reviews {
              fieldGroupName
              reviews {
                fieldGroupName
                heading
                reviewsData{
                review
                stars
                }
                # type
                # rating
                # count
              }
            }
            ... on WpPage_Page_Page_Content_Faqs {
              fieldGroupName
              faqs {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                faqs {
                  question
                  fieldGroupName
                  answer
                }
                link {
                  ... Button
                }
              }
            }
            ... on WpPage_Page_Page_Content_Usps {
              fieldGroupName
              usps {
                fieldGroupName
                backgroundColour {
                  colour
                  colourSize
                  alignColour
                  padding {
                    top
                    bottom
                  }
                }
                showArrows
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText
                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                usps {
                  alignText
                  content
                  heading
                  fieldGroupName
                  imageSize
                  link {
                    ... Button
                  }
                  image {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Team {
              fieldGroupName
              team {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                members {
                  image {
                    ... Image
                  }
                  name
                  title
                  linkedinUrl
                }
              }
            }
            ... on WpPage_Page_Page_Content_Press {
              fieldGroupName
              press {
                fieldGroupName
                press {
                  heading
                  link {
                    ... Button
                  }
                  logo {
                    ... Image
                  }
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_LatestResources {
              fieldGroupName
              latestResources {
                fieldGroupName
                resourceType
                customResources {
                  resource {
                    ... on WpPost {
                      id
                      uri
                      title
                      excerpt
                      featuredImage {
                        node {
                          ...Image
                        }
                      }
                    }
                  }
                  
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Intro {
              fieldGroupName
              intro {
                fieldGroupName
                subheading
                heading
                button {
                  ... Button
                }
              }
            }
            ... on WpPage_Page_Page_Content_Hero {
              fieldGroupName
              hero {
                fieldGroupName
                image {
                  ... Image
                }
                video {
                  ... Video
                }
                fieldGroupName
                form
                showHelpFeatures
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                isHomepage
                language
              }
            }
            ... on WpPage_Page_Page_Content_TwoColumnFeaturesWImage {
              fieldGroupName
              twoColumnFeaturesWImage {
                fieldGroupName
                image {
                  ... Image
                }
                backgroundColour {
                  colourSize
                  alignColour
                  colour
                  padding {
                    top
                    bottom
                  }
                }
                features {
                  content
                  heading
                  illustration {
                    ... Image
                  }
                }
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
                alignImage
                imagePadding
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Awards {
              fieldGroupName
              awards {
                fieldGroupName
                awards {
                  year
                  image {
                    ... Image
                  }
                  heading
                  fieldGroupName
                }
              }
            }
            
            ... on WpPage_Page_Page_Content_Buildings {
              fieldGroupName
              buildings {
                fieldGroupName
              }
            }
            ... on WpPage_Page_Page_Content_Cta {
              fieldGroupName
              cta {
                fieldGroupName
                illustrationSize
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                illustration {
                  ... Image
                }
                fieldGroupName
              }
            }
            ... on WpPage_Page_Page_Content_Careers {
              fieldGroupName
              careers {
                fieldGroupName
                subheading
                careers {
                  title
                  department
                  externalUrl
                  location {
                    city
                    country
                  }
                }
                fieldGroupName
              }
            }
            ... on WpPage_Page_Page_Content_Values {
              fieldGroupName
              values {
                fieldGroupName
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                values {
                  content
                  fieldGroupName
                  heading
                  illustration {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_TestimonialsSlider {
              fieldGroupName
              testimonialsSlider {
                fieldGroupName
                cursorBackgroundColour
                margin {
                  top
                  bottom
                }
                testimonials {
                  testimonial {
                    quote
                    image {
                      ... Image
                    }
                    author {
                      name
                      role
                    }
                    hidetrustpilotwidget
                    quoteTagType
                    link {
                      ... Button
                    }
                    innerBackgroundColour
                    alignImage
                  }
                  fieldGroupName
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                fieldGroupName
              }
            }
            ... on WpPage_Page_Page_Content_TwoColumnFeatures {
              fieldGroupName
              twoColumnFeatures {
                fieldGroupName
                features {
                  content
                  heading
                  illustration {
                    ... Image
                  }
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_TextImageGrid {
              fieldGroupName
              textImageGrid {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                images {
                  image {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Logos {
              fieldGroupName
              logos {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                logos {
                  logo {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Quote {
              fieldGroupName
              quote {
                fieldGroupName
                backgroundColour {
                  colourSize
                  alignColour
                  colour
                  padding {
                    top
                    bottom
                  }                }
                quote
                caption
                author {
                  name
                  role
                }
                illustration {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroPricing {
              fieldGroupName
              heroPricing {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                language
              }
            }
            ... on WpPage_Page_Page_Content_HeroReferral {
              fieldGroupName
              heroReferral {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroPricing2 {
              fieldGroupName
              heroPricing2 {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_PricingWidget {
              fieldGroupName
              pricingWidget {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText
                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                simpletext
                simpletextSecond
                simpletextThird
              }
            }
            ... on WpPage_Page_Page_Content_IntroWImagesParallaxing {
              fieldGroupName
              introWImagesParallaxing {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                images {
                  image {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroSlider {
              fieldGroupName
              heroSlider {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                images {
                  image {
                    ... Image
                  }
                  location
                }
              }
            }
            ... on WpPage_Page_Page_Content_IntroImages {
              fieldGroupName
              introImages {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                desktopImage {
                  ... Image
                }
                mobileImage {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroTwoColumn {
              fieldGroupName
              heroTwoColumn {
                fieldGroupName
                backgroundColour
                showArrow
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                image {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_ImageSlider {
              fieldGroupName
              imageSlider {
                fieldGroupName
                images {
                  image {
                    ... Image
                  }
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_ImageUsps {
              fieldGroupName
              imageUsps {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                usps {
                  heading
                  content
                  image {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroAbout {
              fieldGroupName
              heroAbout {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                illustration {
                  ... Image
                }
                image {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_TextCta {
              fieldGroupName
              textCta {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_PartnerStory {
              fieldGroupName
              featuredStory {
                fieldGroupName
                alignImage
                story {
                  ... on WpPartnerStory {
                    id
                    uri
                    title
                    excerpt
                    featuredImage {
                      node {
                        ...Image
                      }
                    }
                    partnerStoryItem {
                      partnerStoryItem {
                        stat {
                          content
                          heading
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Newsletter {
              fieldGroupName
              newsletter {
                fieldGroupName
                illustrationSize
                heading
                content
                illustration {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_Neighbourhood {
              fieldGroupName
              neighbourhood {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                }
                neighbourhoodImages {
                  neighbourhoodImages {
                    image {
                      ... Image
                    }
                    heading
                    fieldGroupName
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Certificate {
              fieldGroupName
              paymentsTenantsTemplate {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                }
                simpletext
                textTitle
                containersLogoText {
                  containerText
                  containerLogo {
                    ... Image
                  }
                  linkButton {
                    ... Button
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
